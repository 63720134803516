import styled from "@emotion/styled"
import { Seo } from "gatsby-theme-grape-blog/src/components/seo"
import React from "react"
import Bio from "./bio"
import { H1, Header } from "./header"
import Layout, { Main, Sidebar, TwoColumn } from "./layout"
import { ViewAllTagsButton } from "./taxonomy"
import { MailchimpPopup } from "./mailchimp-popup"

const StickyViewTagsButton = styled(ViewAllTagsButton)`
  margin: 0 0 1rem 0;
  top: 1rem;
  position: sticky;
`

export const Page = ({
  meta,
  title,
  seoTitle,
  seoDescription,
  seoSlug,
  seoAuthor,
  seoDateModified,
  seoDatePublished,
  seoSchemaOrgType,
  header,
  top,
  widgets = {},
  children,
}) => {
  const renderHeader = header || (() => <H1>{title}</H1>)
  const { showViewAllTags = true } = widgets

  return (
    <Layout>
      <MailchimpPopup />
      <Seo
        meta={meta}
        schemaOrgType={seoSchemaOrgType}
        title={seoTitle || title}
        description={seoDescription}
        slug={seoSlug}
        author={seoAuthor}
        dateModified={seoDateModified}
        datePublished={seoDatePublished}
      />
      {top && top()}
      <Header>{renderHeader(title)}</Header>
      <TwoColumn>
        <Main>{children}</Main>
        <Sidebar>
          <Bio />
          {showViewAllTags && <StickyViewTagsButton />}
        </Sidebar>
      </TwoColumn>
    </Layout>
  )
}
