import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { PAGE_TYPES } from "gatsby-theme-grape-blog/src/utils/schema-org"
import { Page } from "../../components/page"
import { PostHeader } from "../../components/post-header"
import { PostShareButtons } from "../../components/post-share-buttons"
import { PatreonButton } from "../../components/patreon-button"
import styled from "@emotion/styled"

function getPageSchemaType(collection, slug) {
  if (collection === "pages") {
    const page = slug.toLowerCase().replace(/\/+/g, "")

    if (page === "about") {
      return PAGE_TYPES.ABOUT
    }

    return PAGE_TYPES.DEFAULT
  }

  return PAGE_TYPES.BLOG
}

const Subheader = styled.div`
  display: flex;
  align-items: center;
`

const PageTemplate = ({ data: { mdx: post } }) => {
  const {
    frontmatter: {
      title,
      date,
      updated,
      author,
      tags,
      categories,
      description = post.excerpt,
    },
    fields: { slug, draft, fileSourceName },
  } = post

  const type = getPageSchemaType(fileSourceName, slug)
  const showShareButtons = type === PAGE_TYPES.BLOG

  return (
    <Page
      seoTitle={title}
      seoDescription={description}
      seoSlug={slug}
      seoAuthor={author}
      seoDatePublished={date}
      seoDateModified={updated}
      seoSchemaOrgType={type}
      header={() => (
        <>
          <PostHeader
            title={title}
            tags={tags}
            draft={draft}
            updated={updated}
            categories={categories}
          />
          <Subheader>
            {showShareButtons && <PostShareButtons slug={slug} title={title} />}
            <PatreonButton />
          </Subheader>
        </>
      )}
    >
      <article>
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
      {showShareButtons && <PostShareButtons slug={slug} title={title} />}
    </Page>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      ...customPostDetails
    }
  }
`
