import styled from "@emotion/styled"
import React from "react"
import { Pill } from "./basic"
import { H1 } from "./header"
import { CategoriesList, TagsList } from "./taxonomy"
import { UpdatedBadge } from "./updated-badge"

const HeaderTop = styled.div`
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    margin-left: 0.5rem;
    margin-top: -1.25rem;
  }
`

export const PostHeader = ({ title, draft, updated, categories, tags }) => {
  return (
    <>
      <HeaderTop>
        <CategoriesList categories={categories} />
        {updated && <UpdatedBadge />}
      </HeaderTop>
      <H1>
        {title}
        {draft && <Pill>Draft</Pill>}
      </H1>
      <TagsList tags={tags} />
    </>
  )
}
