import React from "react"
import styled from "@emotion/styled"
import { ShareButtons } from "gatsby-theme-grape-blog/src/components/social/share-buttons"

const ShareButtonsStyled = styled(ShareButtons)`
  margin-top: 1rem;
  margin-bottom: 0;

  li {
    margin-bottom: 0;
  }

  a {
    width: 1.8rem;
    height: 1.8rem;
    background-image: none;

    &[class*="twitter"] {
      padding-left: 0.05rem;
    }

    &[class*="linkedin"] {
      padding-left: 0.01rem;
    }

    &[class*="facebook"] {
      padding-right: 0.05rem;
    }

    &[class*="reddit"] {
      padding-bottom: 0.05rem;
      padding-right: 0.05rem;
    }
  }
`

export const PostShareButtons = ({ slug, title }) => {
  return (
    <ShareButtonsStyled
      slug={slug}
      textOrTitle={title}
      platforms={["twitter", "linkedin", "facebook", "reddit"]}
    />
  )
}
